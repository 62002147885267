<template>
  <div>
    <section id="content">
      <div class="section nobg nomargin">
        <div class="container">
          <div class="heading-block center">
            <h2>
              Meet Our Team
            </h2>
          </div>
          <div class="tabs tabs-responsive justified-tabs tabs-resources">
            <ul class="tab-nav overflow-x-auto">
              <li
                class="cursor-pointer whitespace-nowrap px-3.5"
                v-on:click="toggleTabs(1)"
                v-bind:class="{
                  'bg-transparent text-gray-100': openTab !== 1,
                  'text-secondary bg-main': openTab === 1,
                }"
              >
                The Team
              </li>
              <li
                class="cursor-pointer whitespace-nowrap px-3.5"
                v-on:click="toggleTabs(2)"
                v-bind:class="{
                  'bg-transparent text-gray-100': openTab !== 2,
                  'text-secondary bg-main': openTab === 2,
                }"
              >
                Advisors
              </li>
              <li
                class="cursor-pointer whitespace-nowrap px-3.5"
                v-on:click="toggleTabs(3)"
                v-bind:class="{
                  'bg-transparent text-gray-100': openTab !== 3,
                  'text-secondary bg-main': openTab === 3,
                }"
              >
                Legal
              </li>
            </ul>
            <div class="tab-container">
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 1,
                  block: openTab === 1,
                }"
              >
                <div class="row champions-description">
                    <div class="col-md-3 our-team-item" v-for="n in tab1" :key="n">
                      <div class="champion-desc-box nobg">
                        <img class="mx-auto team-photo" :src=n.src />
                        <h3 class="team-name">
                          {{ n.staff }}
                        </h3>
                        <p class="team-position">
                          {{ n.title }}
                        </p>
                      </div>
                    </div>
                </div>
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 2,
                  block: openTab === 2,
                }"
              >
                <div class="row champions-description">
                    <div v-for="n in tab2" :key="n">
                      <h3 class="team-name">
                        {{ n.advisor }}
                      </h3>
                      <p v-for="m in n.text" :key="m">
                        {{ m }}
                      </p>
                      <hr/>
                    </div>
                </div>
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 3,
                  block: openTab === 3,
                }"
              >
                <div class="row champions-description">
                    <div v-for="n in tab3" :key="n" class="teamdiv">
                      <h3 class="team-name">
                        {{ n.title }}
                      </h3>
                      <p v-for="m in n.text" :key="m">
                        {{ m }}
                      </p>
                      <hr/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ResourceFeature from "../components/resource-feature.vue";
export default {
  components: {
    ResourceFeature,
  },
  data() {
    return {
      openTab: 1,
      tab1: [
        {
          staff: "Kyai Mullei",
          title: "Co-founder CEO",
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643116717/commonshare/staff/team-1.png",
        },
        {
          staff: "Ben Chegeh",
          title: "Technical Lead",
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643116717/commonshare/staff/team-5.png",
        },
        {
          staff: "Pauline Adisa",
          title: "Customer Care Manager",
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643116717/commonshare/staff/team-4.png",
        },
        {
          staff: "Lynne Kabutiei",
          title: "Finance Manager",
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643116717/commonshare/staff/team-6.png",
        },
        {
          staff: "Michael Okinyi",
          title: "Head of Sales",
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643116718/commonshare/staff/team-8.png",
        },
        {
          staff: "Matt Roberts",
          title: "Business Development Lead",
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643116718/commonshare/staff/team-3.png",
        },
        {
          staff: "Bridgit Musembi",
          title: "Business Development Officer",
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643116718/commonshare/staff/team-7.png",
        },
        {
          staff: "Rael Akrapa",
          title: "Customer Care",
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto,w_191/v1643116718/commonshare/staff/team-10.png",
        },
      ],
      tab2: [
        {
          advisor: "Carol Caruso",
          text:[
            "Carol Caruso is Accion’s SVP of Channels & Technology (C&T); responsible for bringing innovative delivery channels and technology solutions to Accion partners and increasing sector development through advisory services and initiatives. C&T has completed digital financial services projects in LATAM, Asia and Africa including mobile banking, partnering with MNOs, launching/improving agent networks and deploying various innovative technologies such as biometrics, smart ATMs and field based tablet solutions to increase efficiencies. Additionally, C&T works with FinTech companies to help improve and expand their offerings for driving financial inclusion such as diagnostic and improvement planning, business development, feature enhancement or pilot support.",
            "Carol has 20 years of experience in business and IT consulting services in developed and developing countries, including planning and implementation of mobile banking, agent banking and other digital channels.",
          ]
        },
        {
          advisor: "Greg Reeve",
          text:[
            "Greg Reeve has been working in Mobile since 2001 and Mobile Payments from 2009 where he led M-Pesa Globally for Vodafone, more recently he has led Millicom (Tigo) as General Manager for Mobile Financial Services. He is also member of the Board of Directors of the companies Milvik (Bima), which provides an end-to-end mobile insurance solution for mobile operators in emerging markets, Rswitch, National E-payment switch of Rwanda and the Tigo Mobile Payments business in Ghana.",
            "He has a background in large scale IT projects and telecommunications and holds a number of published patents in the fields of Smartphone App Security, Machine to Machine and Mobile Money.",
          ]
        },
        {
          advisor: "Jean Stephane Gourevitch",
          text:[
            "Jean Stephane is an expert of the digital and mobile convergence ecosystem with over 23 years global experience at senior management levels.",
            "Jean Stephane focuses in particular on digital/mobile communications, digital/mobile payments, digital/mobile banking, digital/mobile commerce, Fintech and innovation. My expertise lies in strategy, business development, regulatory affairs, public policy, Government Relations, stakeholders relationships management, communication and PR.",
            "Jean Stephane has held senior management positions with blue-chip International telecoms businesses such as Everything Everywhere Ltd. (EE), Orange, Global Crossing, France Telecom, Verizon Business, Colt Technology Services Ltd. Jean Stephane has worked for OFCOM, the UK digital communications regulator. He also held senior management positions with Deloitte Consulting and with strategic marketing and PR/Communications firms.",
          ]
        },
        {
          advisor: "DJ Koeman",
          text:[
            "DJ Koeman has skills as team builder, motivator, communicator and facilitator. He is capable of assessing idea’s/projects both commercially as well as technically, always with a focus on the customer. DJ is a seasoned professional with over 15 years of experience in telecoms and mobile banking in various positions across different operators and regions in the world. DJ is very customer and results oriented, culturally sensitive with an excellent sense of humor! He has experience across both fixed as well as mobile, B2C as well as B2B, mostly in commercial roles (product/marketing/project management, distribution, etc.)",
            "DJ has consulted in both public as well as private sector. He has expertise in Mobile Number Portability (MNP), Mobile Virtual Network Operator (MVNO), Mobile Virtual Network Enabler (MVNE), devices and mobile banking.",
          ]
        },
      ],
      tab3: [
        {
          title: "What is the legal entity behind the M-Changa service?",
          text: [
            "MOBI CHANGA LIMITED (KENYA)",
            "Subsidiary",
            "Westcom Building, Mahiga Mairu Avenue, off Waiyaki Way, 3rd Floor, Block B",
            "Customer Care: " + this.supportEmail
          ]
        },
        {
          title: "What is the legal status of M-Changa?",
          text: [
            "Compliant - Kenya Revenue Authority (KRA)"
          ]
        },
      ],
    };
  },
  methods: {
    toggleTabs: function(tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>

<style scoped>
.our-team-item {
  position: relative;
  background-color: #f9f7f6;
  border-radius: 5px;
  padding: 18px 12px;
  text-align: center;
  height: 100%;
  transition: all .2s;
}
.our-team-item:hover{
  background-color: #475b06;
}
.team-name{
  font-family: 'museo_sans_rounded900';
  font-size: 20px;
  color: #475b06;
  transition: all .2s;
  margin-bottom: 0;
}
.our-team-item .team-position {
  margin-bottom: 0;
  transition: all .2s;
}
.our-team-item .team-photo {
    width: 190px;
    height: 190px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: 14px;
    margin-bottom: 20px;
    filter: grayscale(100%);
    transition: all .2s;
}
img {
    max-width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}
.teamdiv{
  width: 100%;
}
</style>
